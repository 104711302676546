<template>
  <div class="unload-list">
    <r-tabs @input="changeTab">
      <r-tab
        v-for="tab in types"
        :id="tab.id"
        :key="tab.id"
        :name="tab.title"
        :active="viewTab === tab.id"
      />
    </r-tabs>
    <r-search-input
      :filter-text="filterText"
      :is-loading="loading"
      @change="filterText = $event"
    />
    <div
      v-if="filteredList.length"
      class="unload-list__wrapper"
    >
      <unload-list-item
        v-for="item in filteredList"
        :key="item.id"
        :item="item"
        :active="isActive(item.id)"
        @click.native="clickHandler(item)"
      />
    </div>
    <div
      v-else
      class="unload-list__no-data"
    >
      <r-text>
        {{
          !!filterText
            ? 'Согласно фильтру данные отсутствуют'
            : 'Нет данных'
        }}
      </r-text>
    </div>
  </div>
</template>

<script>
import { types } from '../../configs'

export default {
  components: {
    unloadListItem: () => import('./unload-list-item')
  },
  data() {
    return {
      loading: false,
      filterText: '',
      data: null,
      viewTab: types[0].id,
      types
    }
  },
  computed: {
    activeItem() {
      return this.$store.state.unload.activeItem || null
    },
    filteredList() {
      const query = String(this.filterText).trim().toLowerCase()

      return this.data?.filter(e => {
        return String(e.name).includes(query)
      }) || []
    },
    updateList: {
      get() {
        return this.$store.state.unload.updateList
      },
      set(val) {
        this.$store.commit('UNLOAD_SET_FIELD', ['updateList', val])
      }
    }
  },
  watch: {
    updateList(val) {
      if (val) {
        this.loadData()
        this.updateList = false
      }
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    changeTab(val) {
      this.viewTab = val
      this.loadData()
    },
    isActive(id) {
      return this.activeItem?.id === id
    },
    async loadData() {
      try {
        this.loading = true

        this.data = []

        if (this.filterText) {
          this.filterText = ''
        }

        const activeType = this.types?.find(t => t.id === this.viewTab)

        if (!activeType) return

        const { url, key, type } = activeType
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })

        this.setData(data, key, type)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    setData(data, key, type) {
      const unique = {}

      this.data = data?.filter(e => {
        if (!unique[e.id]) {
          unique[e.id] = true
          return true
        } else {
          return false
        }
      })?.map(e => {
        return {
          ...e,
          type,
          name: e[key],
          status: this.getStatus(e)
        }
      }) || null
    },
    getStatus({ cargo_places_count, cargo_places_assigned_count }) {
      if (cargo_places_count === 0) {
        return 'default'
      } else if (cargo_places_count === cargo_places_assigned_count) {
        return 'unloaded'
      } else if (cargo_places_assigned_count > 0) {
        return 'unloading'
      } else {
        return 'waiting'
      }
    },
    clickHandler(id) {
      if (this.activeItem === id) return

      this.$store.commit('UNLOAD_SET_FIELD', ['activeItem', id])
    }
  }
}
</script>

<style lang="scss" scoped>
.unload-list {
  display: grid;
  grid-gap: 0.5rem;
  padding: 0.5rem;
  height: 100%;
  overflow: hidden;
  align-content: flex-start;

  &__wrapper {
    display: grid;
    grid-gap: 0.5rem;
    overflow: auto;
    height: 100%;
  }

  &__no-data {
    display: grid;
    padding: 1rem;
    justify-content: center;
    text-align: center;
  }
}
</style>
